import React from "react";
import classes from "./Card.module.css";
// MUI
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
// import { Grid, ButtonBase, IconButton } from "@material-ui/core";
// import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
// MUI
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const Card = (props) => {
  const icon = props.submitted ? (
    <CheckBoxIcon style={{ color: "#E57373", fontSize: "1.5rem" }} />
  ) : (
    <AddBoxRoundedIcon style={{ color: "white", fontSize: "1.5rem" }} />
  );

  const handleCardOpen = () => {
    props.handleOpen(true);
    props.executeScroll();
  };

  const personnalisableTag = !props.submitted ? (
    <div className={classes.Tag} style={{ backgroundColor: "#3D9DC7" }}>
      <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
      <p>Personnalisable</p>
    </div>
  ) : (
    <div className={classes.Tag} style={{ backgroundColor: "#38BB6E" }}>
      <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
      <p>Personnalisé !</p>
    </div>
  );

  return (
    <div className={classes.Card} onClick={handleCardOpen}>
      <div className={classes.Thumbnail}>
        <img
          alt="thumbnail"
          src={require(`../../../../assets/travaux/${props.imgSrc}`)}
        />
      </div>

      <div className={classes.Main}>
        <div className={classes.Titre}>
          <p>
            <span>{props.titleStrong}</span> {props.titleWeak}
          </p>
        </div>
        <div className={classes.Description}>
          <span> {props.description}</span>
        </div>

        <div className={classes.TagContainer}>
          {props.tags === "personnalisable" ? personnalisableTag : null}
        </div>
      </div>

      <div className={classes.Icon}>
        <KeyboardArrowRightRoundedIcon style={{ fontSize: "1.8rem" }} />
      </div>
    </div>
  );
};

export default Card;
