import React, { useState } from "react";
import classes from "./ncTwo.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NcTwo = ({ card, dispatch, width, currentUser, show }) => {
  const classesMUI = useStyles();
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    let answers = {};

    answers["comment"] = comment;
    uploadAnswer(currentUser.uid, "nc2", answers, dispatch);
  };

  // Title
  const title = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>La gestion de l’eau</h3>
        <h4>dans le futur quartier</h4>
      </div>
    </header>
  );

  // Description
  const description = (
    <div className={classes.Description}>
      <span
        className={classes.Emoji}
        role="img"
        style={{
          fontSize: "2rem",
          border: "none",
          marginTop: "-.8rem",
          marginRight: "-.8rem",
        }}
        aria-label="emoji"
      >
        👉
      </span>
      <p>
        Si la nature du sol le permet, une gestion “zéro rejet” des eaux
        pluviales sera mise en œuvre.
      </p>
    </div>
  );

  // Section 1
  const sectionOne = (
    <div className={classes.SectionLeft}>
      <div className={classes.TextArrowToRight}>
        <p>
          Les eaux pluviales seront gérées dans les espaces publics à travers
          des <span>fossés végétalisés</span> (noues) et{" "}
          <span>espaces verts creux</span>.
        </p>
        <div className={classes.ArrowToRight}>
          <img
            style={{ width: "100%" }}
            alt="Arrow"
            src={require(`../../../../assets/travaux/arrowRight.svg`)}
          />
        </div>
      </div>
      <div>
        <div className={classes.Illustration}>
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC2.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 2
  let sectionTwo = (
    <div className={classes.SectionRight}>
      <div className={classes.TextOnly}>
        <p>
          Les eaux s’infiltrent dans les sols{" "}
          <span>sans rejet dans les réseaux existants</span>, afin de lutter
          contre les inondations par ruissellement et limiter les pollutions
          dans le milieu naturel lors d’épisodes de fortes pluies.
        </p>
      </div>
    </div>
  );

  // Button Valider
  // disabled={card.comment === "" && card.questions[0].value === ""}
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={comment === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Commentaire
  const commentaire = (
    <div className={classes.CommentSection}>
      <TextField
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        label="J'ai un commentaire !"
        style={{ width: "100%" }}
        color="secondary"
        size="small"
      />
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 768) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default NcTwo;
