import React, { useState } from "react";
import classes from "./ncFour.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NcFour = ({ card, dispatch, width, currentUser, show }) => {
  const classesMUI = useStyles();
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    let answers = {};

    answers["comment"] = comment;
    uploadAnswer(currentUser.uid, "nc4", answers, dispatch);
  };

  // Title
  const title = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>La faune et la flore</h3>
        <h4>du futur quartier</h4>
      </div>
    </header>
  );

  // Description
  const description = (
    <div className={classes.Description}>
      <span
        className={classes.Emoji}
        role="img"
        style={{
          fontSize: "2rem",
          border: "none",
          marginTop: "-.8rem",
          marginRight: "-.8rem",
        }}
        aria-label="emoji"
      >
        👉
      </span>
      <p>
        La conservation des espèces est un enjeu du projet du futur quartier.
      </p>
    </div>
  );

  // Section 1
  const sectionOne = (
    <div className={classes.SectionLeft}>
      <div className={classes.TextArrowToRight}>
        <p>
          A travers les clôtures, il sera prévu des petits aménagements pour
          favoriser <span>le déplacement de la faune</span>.
        </p>
        <div className={classes.ArrowToRight}>
          <img
            style={{ width: "100%" }}
            alt="Arrow"
            src={require(`../../../../assets/travaux/arrowRight.svg`)}
          />
        </div>
      </div>
      <div>
        <div className={classes.Illustration}>
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC4-1.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 2
  let sectionTwo = <div></div>;
  if (width < 768) {
    sectionTwo = (
      <div className={classes.SectionRight}>
        <div className={classes.TextArrowToLeft}>
          <p>
            Une part des plantations sera mellifères pour aider les{" "}
            <span>insectes pollinisateurs</span> et des végétaux fructifères
            seront intégrés pour alimenter l’avifaune.
          </p>
          <div className={classes.ArrowToLeft}>
            <img
              style={{ width: "100%" }}
              alt="Arrow"
              src={require(`../../../../assets/travaux/arrowLeft.svg`)}
            />
          </div>
        </div>
        <div>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC4-3.svg`)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    sectionTwo = (
      <div className={classes.SectionRight}>
        <div>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC4-3.svg`)}
            />
          </div>
        </div>
        <div className={classes.TextArrowToLeft}>
          <p>
            Une part des plantations sera mellifères pour aider les{" "}
            <span>insectes pollinisateurs</span> et des végétaux fructifères
            seront intégrés pour alimenter l’avifaune.
          </p>
          <div className={classes.ArrowToLeft}>
            <img
              style={{ width: "100%" }}
              alt="Arrow"
              src={require(`../../../../assets/travaux/arrowLeft.svg`)}
            />
          </div>
        </div>
      </div>
    );
  }

  // Section 3
  const sectionThree = (
    <div className={classes.SectionLeft}>
      <div className={classes.TextArrowToRight}>
        <p>
          Des dispositifs mis en place pourront{" "}
          <span>accueillir la biodiversité et la renforcer</span> : Hôtels à
          insectes, nichoirs, rondins de protection, tas de pierres sèches, tas
          de bois, bordures surbaissées…
        </p>
        <div className={classes.ArrowToRight}>
          <img
            style={{ width: "100%" }}
            alt="Arrow"
            src={require(`../../../../assets/travaux/arrowRight.svg`)}
          />
        </div>
      </div>
      <div>
        <div className={classes.Illustration}>
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC4-2.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 4
  const sectionFour = (
    <div className={classes.SectionTextIllu}>
      <div className={classes.TextOnly}>
        <p>
          Une signalétique pédagogique spécifique (faune / flore) pourra être
          mise en place afin de <span>sensibiliser les habitants</span> sur les
          choix des plantations, le mode de gestion alternative des espaces
          verts et d'expliquer les différents dispositifs de biodiversité
          (nichoirs à oiseaux, abris à insectes....).
        </p>
      </div>
      <div>
        <div
          className={classes.IllustrationOnly}
          style={{ width: "10rem", margin: "0 auto" }}
        >
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC4-4.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 5
  const sectionFive = (
    <div className={classes.SectionTextIllu} style={{ marginTop: "1rem" }}>
      <div className={classes.TextOnly}>
        <p>
          Certains espaces pourront être destinés aux habitants ou écoles dans
          le cadre d'une association de type <span>jardins pédagogiques</span>.
        </p>
      </div>
      <div>
        <div
          className={classes.IllustrationOnly}
          style={{ width: "10rem", margin: "0 auto" }}
        >
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC4-5.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Button Valider
  // disabled={card.comment === "" && card.questions[0].value === ""}
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={comment === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Commentaire
  const commentaire = (
    <div className={classes.CommentSection}>
      <TextField
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        label="J'ai un commentaire !"
        style={{ width: "100%" }}
        color="secondary"
        size="small"
      />
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 768) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree} {sectionFour}
                {sectionFive}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree} {sectionFour}
                {sectionFive}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default NcFour;
