import { db } from "../config/firebase";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  // TODO: switch from client to server
  db.collection("locataires")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
      // let csv = "Nom,Mobile,Réference locataire,Date\n";
      // snapshot.forEach((doc) => {
      //   csv += `${doc.data().fullName},${doc.data().numTel},${
      //     doc.data().refLoc
      //   },${doc.data().dateCreation}`;
      //   csv += "\n";
      // });
      // console.log(csv);
    })
    .catch((err) => console.log(err));

  db.collection("resultats")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_REPONSES", value: snapshot.size });
    })
    .catch((err) => console.log(err));
};
