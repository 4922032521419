import React from "react";
import classes from "./CardList.module.css";

import Card from "./Card/Card";

const CardList = ({ cards, dispatch, executeScroll, filters }) => {
  const openHandler = (index) => {
    dispatch({ type: "SET_SHOWCARD", show: true });
    dispatch({ type: "SET_SELECTEDCARD", selectedCard: index });
  };

  const { personnalisable, informative } = filters;
  const personnalisableCheck =
    personnalisable.value || (!personnalisable.value && !informative.value);
  const informativeCheck =
    informative.value || (!informative.value && !personnalisable.value);

  return (
    <div className={classes.CardList}>
      <div className={classes.List}>
        {cards.map((card, index) => {
          if (
            (card.tags === "informative" && informativeCheck) ||
            (card.tags === "personnalisable" && personnalisableCheck)
          ) {
            return (
              <Card
                key={card.id}
                cardName={card.id}
                handleOpen={() => openHandler(index)}
                titleStrong={card.titleStrong}
                titleWeak={card.titleWeak}
                imgSrc={card.thumbnail}
                submitted={card.submitted}
                type={card.type}
                tags={card.tags}
                executeScroll={executeScroll}
                description={card.description.substring(0, 58) + "..."}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default CardList;
