import React from "react";

export const travauxCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’espace public",
    titleWeak: "",
    description:
      "Le croisement entre la rue du pont de Chennevières, la rue Olivier d’Ormesson et l’avenue du Général Leclerc marque historiquement l’entrée de la ville mais les activités vues comme gênantes y ont été rejetées depuis. Le futur quartier permet de repenser ce croisement par l’aménagement d’une place vitrine de l’identité innovante et de l’attractivité de votre ville. Quelle place pour demain ?",
    thumbnail: "c1_q1rep1.svg",
    imgSrc: "c1_q1rep1.svg",
    planMasse: "c1_q1rep1.svg",
    tags: "personnalisable",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type de place attenante aux commerces et / ou services en RDC ?",
        options: [
          {
            label: "Une place passante avec un traitement sommaire du parvis.",
            value: "q1rep1",
          },
          {
            label:
              "Un parvis/espace propice aux échanges et rencontres avec divers mobiliers urbains.",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’usage de la voirie",
    titleWeak: "secteur 1",
    description:
      "Au même titre que la rue du Pont de Chennevières dont elle est la continuité, l’avenue du Général Leclerc constitue une véritable entrée de ville à valoriser. Une des pistes envisagées est de requalifier cette voirie en la rendant plus agréable et plus en adéquation avec vos besoins et la perspective d’une entrée de ville digne de ce nom! Aidez-nous à requalifier cet espace!",
    thumbnail: "c2_q1rep1.svg",
    imgSrc: "c2_q1rep1.svg",
    planMasse: "c2_plan.png",
    tags: "personnalisable",
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Comment re-qualifieriez-vous cette voirie ?",
        options: [
          {
            label: "Ne rien changer, mais apporter un rafraîchissement.",
            value: "q1rep1",
          },
          {
            label:
              "Requalifier la voirie pour une meilleure circulation: plus de place pour les piétons et la végétation.",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’usage de la voirie",
    titleWeak: "secteur 2",
    description:
      "Au même titre que l’avenue du Général Leclerc dont elle est la continuité, la rue du Pont de Chennevières constitue une véritable entrée de ville à revaloriser. Une des pistes envisagées est de requalifier cette voirie en la rendant plus praticable, plus agréable et plus en adéquation avec vos besoins et la perspective d’une entrée de ville digne de ce nom! Aidez-nous à requalifier cet espace!",
    thumbnail: "c3_q1rep1.svg",
    imgSrc: "c3_q1rep1.svg",
    planMasse: "c2_q1rep1.svg",
    tags: "personnalisable",
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Comment re-qualifieriez-vous cette voirie ?",
      options: {
        q1rep1: {
          label: "Ne rien changer, mais apporter un rafraîchissement.",
          value: "q1rep1",
        },
        q1rep2: {
          label:
            "Requalifier la rue en boulevard urbain en séparant les trottoirs de la voirie par une bande végétalisée. (de type “noues”)",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Comment re-qualifieriez-vous cette voirie ?",
        options: [
          {
            label: "Ne rien changer, mais apporter un rafraîchissement.",
            value: "q1rep1",
          },
          {
            label:
              "Requalifier la rue en boulevard urbain en séparant les trottoirs de la voirie par une bande végétalisée. (de type “noues”)",
            value: "q1rep2",
          },
          {
            label:
              "Requalifier la rue en intégrant des pistes cyclables et une bande végétalisée (de type “noues”) au milieu de la rue.",
            value: "q1rep3",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Cadre de vie,",
    titleWeak: "santé et bien-être",
    description:
      "Comment le cadre de vie du futur quartier peut - de part sa conception - valoriser votre santé et votre bien-être ? Pour y répondre, plusieurs pistes sont envisagées...la nature, le sport ou encore le calme...Cependant, les besoins sont très différents selon les âges, habitudes de chacun...Quel forme prendrait, pour vous, un cadre de vie qui concilierait santé psychique et urbaine et bien-être?",
    thumbnail: "c4_q1rep1.svg",
    imgSrc: "c4_q1rep1.svg",
    planMasse: "c2_q1rep1.svg",
    tags: "personnalisable",
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Comment re-qualifieriez-vous cette voirie ?",
      options: {
        q1rep1: {
          label: "Ne rien changer, mais apporter un rafraîchissement.",
          value: "q1rep1",
        },
        q1rep2: {
          label:
            "Requalifier la rue en boulevard urbain en séparant les trottoirs de la voirie par une bande végétalisée. (de type “noues”)",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Qu’apporteriez-vous au futur quartier pour le rendre plus agréable et mieux soucieux de la santé de ses habitants ?",
        options: [
          {
            label: "Un parcours sportif.",
            value: "q1rep1",
          },
          {
            label:
              "Des espaces pour se ressourcer (belvédères avec des vues, ambiance plus intimes).",
            value: "q1rep2",
          },
          {
            label: "Des jardins potagers publics ou à l’échelle de lots.",
            value: "q1rep3",
          },
          {
            label: "Une voie dédiée aux piétons et mobilité douce.",
            value: "q1rep4",
          },
          {
            label: "Une plus grande présence de la nature et de l’eau.",
            value: "q1rep5",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "nc1",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Les formes urbaines",
    titleWeak: "du futur quartier",
    description:
      "Concilier la mise en valeur du cadre paysager et le tissu pavillonnaire existant est l’essence du projet de la ZAC des Coteaux d’Ormesson !",
    thumbnail: "NC1-1.svg",
    tags: "informative",
    submitted: false,
    comment: "",
  },
  {
    id: "nc2",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La gestion de l’eau",
    titleWeak: "dans le futur quartier",
    description:
      "Si la nature du sol le permet, une gestion “zéro rejet” des eaux pluviales sera mise en œuvre. Les eaux pluviales seront gérées dans les espaces publics à travers des fossés végétalisés (noues) et espaces verts creux.",
    thumbnail: "NC2.svg",
    tags: "informative",
    submitted: false,
    comment: "",
  },
  {
    id: "nc3",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "L’ambiance paysagère",
    titleWeak: "du futur quartier",
    description:
      "Afin de préserver le patrimoine paysager remarquable du site, les arbres existants seront conservés au maximum sur l’ensemble du projet. Les plantations nouvelles, quant à elles, seront mises en œuvre pour préserver le sol.",
    thumbnail: "c3_thumb.png",
    tags: "informative",
    submitted: false,
    comment: "",
  },
  {
    id: "nc4",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La faune et la flore",
    titleWeak: "du futur quartier",
    description:
      "La conservation des espèces est un enjeu du projet du futur quartier. A travers les clôtures, il sera prévu des petits aménagements pour favoriser le déplacement de la faune.",
    thumbnail: "NC4-3.svg",
    tags: "informative",
    submitted: false,
    comment: "",
  },
  // {
  //   id: "nc1",
  //   type: "nonConcertable",
  //   layout: "nonConcertableLayoutTwo",
  //   titleStrong: "Réflexion sur",
  //   titleWeak: "le stationnement",
  //   description:
  //     "Le stationnement au niveau de la Mairie est amené à être réorganisé. Il est aujourd’hui organisé de la manière suivante :",
  //   jsxDescription: (
  //     <React.Fragment>
  //       <div>
  //         <p>
  //           Le stationnement au niveau de la Mairie est amené à être réorganisé.
  //         </p>
  //         <br />
  //         <p>Il est aujourd’hui organisé de la manière suivante :</p>
  //         <ul>
  //           <li>
  //             <span style={{ color: "#e31869", fontWeight: "bold" }}>
  //               32 places
  //             </span>{" "}
  //             devant l'hôpital
  //           </li>
  //           <li>
  //             <span style={{ color: "#e31869", fontWeight: "bold" }}>
  //               30 places
  //             </span>{" "}
  //             derrière la mairie
  //           </li>
  //           <li>
  //             <span style={{ color: "#e31869", fontWeight: "bold" }}>
  //               65 places
  //             </span>{" "}
  //             entre le marché et l’yvette
  //           </li>
  //         </ul>
  //         <br />
  //         <p>
  //           Nous prévoyons de construire un parking de 150 places en sous-sol,
  //           sous le nouveau marché.
  //         </p>
  //         <br />
  //         <p>
  //           Le stationnement sera gratuit les jours de marché et le weekend.
  //         </p>
  //         <br />
  //         <p>
  //           Une trentaine de places de stationnement sera conservée en surface
  //           notamment pour faciliter le stationnement pour l’école
  //           Sainte-Suzanne aujourd’hui difficile.
  //         </p>
  //       </div>
  //     </React.Fragment>
  //   ),
  //   jsxNote: <p></p>,
  //   detailsTravaux: [
  //     {
  //       id: 1,
  //       emoji: "",
  //       detail: "(à determiner)",
  //     },
  //   ],
  //   thumbnail: "NC1_thumb.png",
  //   video: {
  //     url: "https://youtu.be/nhSwFnkwvQI",
  //     width: 320,
  //     height: 400,
  //   },
  //   planMasse: "illustrationAttente.png",
  //   planMasseTexte: "(à déterminer)",
  //   duration: "(à déterminer)",
  //   periode: "(à déterminer)",
  //   tags: ["informative"],
  //   submitted: false,
  //   questions: {},
  //   comment: "",
  // },
];
