import React, { useContext } from "react";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";
// MUI COMPONENTS
// import Radio from "../../muiComponents/Radio/Radio";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const QuestionSeven = (props) => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <p style={{ marginBottom: ".5rem", fontSize: "1.1rem" }}>
        Etes-vous habitant·e d’Ormesson-sur-Marne ?
      </p>
      <RadioGroup
        aria-label={profileSetupData.habitOrmesson.elementConfig.name}
        name={profileSetupData.habitOrmesson.elementConfig.name}
        value={profileSetupData.habitOrmesson.value}
        onChange={(e) =>
          dispatch({ type: "SET_HABITORMESSON", habitOrmesson: e.target.value })
        }
      >
        {Object.keys(profileSetupData.habitOrmesson.elementConfig.options).map(
          (option, indexOption) => (
            <FormControlLabel
              style={{
                marginTop: ".3rem",
              }}
              key={
                profileSetupData.habitOrmesson.elementConfig.options[option]
                  .value
              }
              value={
                profileSetupData.habitOrmesson.elementConfig.options[option]
                  .value
              }
              control={<Radio color="secondary" />}
              label={
                profileSetupData.habitOrmesson.elementConfig.options[option]
                  .displayValue
              }
            />
          )
        )}
      </RadioGroup>
    </div>
  );
};

export default QuestionSeven;
