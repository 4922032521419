import React, { useState } from "react";
import classes from "./ncThree.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NcThree = ({ card, dispatch, width, currentUser, show }) => {
  const classesMUI = useStyles();
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    let answers = {};

    answers["comment"] = comment;
    uploadAnswer(currentUser.uid, "nc3", answers, dispatch);
  };

  // Title
  const title = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>L’ambiance paysagère</h3>
        <h4>du futur quartier</h4>
      </div>
    </header>
  );

  // Description
  const description = (
    <div className={classes.Description}>
      <span
        className={classes.Emoji}
        role="img"
        style={{
          fontSize: "2rem",
          border: "none",
          marginTop: "-.8rem",
          marginRight: "-.8rem",
        }}
        aria-label="emoji"
      >
        👉
      </span>
      <p>
        Afin de préserver le patrimoine paysager remarquable du site, les arbres
        existants seront conservés au maximum sur l’ensemble du projet. Les
        plantations nouvelles, quant à elles, seront mises en œuvre pour
        préserver le sol.
      </p>
    </div>
  );

  // Section 1
  let sectionOne = <div></div>;

  if (width < 768) {
    sectionOne = (
      <div className={classes.SectionTextIllu} style={{ marginTop: "2rem" }}>
        <div className={classes.TextOnly}>
          <p>
            Pour éviter le désherbage chimique, des techniques alternatives
            devront être mises en œuvre, notamment par{" "}
            <span>l’emploi de paillage</span> des massifs se décomposant
            lentement pour nourrir les sols.
          </p>
        </div>
        <div>
          <div className={classes.IllustrationOnly}>
            <img
              style={{ width: "100%" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC3-2.svg`)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    sectionOne = (
      <div className={classes.SectionLeft} style={{ paddingBottom: 0 }}>
        <div className={classes.TextArrowToRight}>
          <p>
            Pour éviter le désherbage chimique, des techniques alternatives
            devront être mises en œuvre, notamment par{" "}
            <span>l’emploi de paillage</span> des massifs se décomposant
            lentement pour nourrir les sols.
          </p>
          <div className={classes.ArrowToRight}>
            <img
              style={{ width: "100%" }}
              alt="Arrow"
              src={require(`../../../../assets/travaux/arrowRight.svg`)}
            />
          </div>
        </div>
        <div>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%", paddingTop: "2.5rem" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC3-2.svg`)}
            />
          </div>
        </div>
      </div>
    );
  }

  // Section 2
  let sectionTwo = (
    <div className={classes.SectionTextIllu}>
      <div className={classes.TextOnly}>
        <p>
          Les alignements de ces plantations (constitués de bosquets, arbustes,
          plantes vivaces et pieds d’arbres existant plantés) souligneront les
          rues et les chemins du futur quartier. Les pieds des constructions et
          les limites de propriété seront également plantés avec des{" "}
          <span>plantes grimpantes</span> le long des clôtures et des bandes de
          plantations plus basses. Cela donnera une{" "}
          <span>ambiance végétale</span> au quartier et permettra d’améliorer sa
          biodiversité.
        </p>
      </div>
      <div>
        <div
          className={classes.IllustrationOnly}
          style={{ width: "20rem", margin: "0 auto" }}
        >
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC3-1.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 3
  const sectionThree = (
    <div className={classes.SectionTextIllu}>
      <div className={classes.TextOnly}>
        <p>
          Pour éclairer ces rues et chemins, un éclairage novateur et économe
          sera mis place: des <span>leds</span> pour consommer moins et un{" "}
          <span>éclairage modulaire</span> selon les périodes de l’année pour
          éclairer plus malin. Dans cette logique, les rues, sentes et places
          auront toutes des éclairage adaptés: bas pour accompagner les piétons
          sur les chemins, plus hauts pour la circulation des voitures et plus
          hauts encore sur les belvédères pour marquer les centralités du futur
          quartier. Et pourquoi pas... mettre en scène l’éclairage !
        </p>
      </div>
      <div>
        <div
          className={classes.IllustrationOnly}
          style={{ width: "20rem", margin: "0 auto" }}
        >
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC3-3.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Button Valider
  // disabled={card.comment === "" && card.questions[0].value === ""}
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={comment === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Commentaire
  const commentaire = (
    <div className={classes.CommentSection}>
      <TextField
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        label="J'ai un commentaire !"
        style={{ width: "100%" }}
        color="secondary"
        size="small"
      />
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 768) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default NcThree;
