import React, { useState } from "react";
import classes from "./ncOne.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NcOne = ({ card, dispatch, width, currentUser, show }) => {
  const classesMUI = useStyles();
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    let answers = {};

    answers["comment"] = comment;
    uploadAnswer(currentUser.uid, "nc1", answers, dispatch);
  };

  // Title
  const title = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>Les formes urbaines</h3>
        <h4>du futur quartier</h4>
      </div>
    </header>
  );

  // Description
  const description = (
    <div className={classes.Description}>
      <span
        className={classes.Emoji}
        role="img"
        style={{
          fontSize: "2rem",
          border: "none",
          marginTop: "-.8rem",
          marginRight: "-.8rem",
        }}
        aria-label="emoji"
      >
        👉
      </span>
      <p>
        Concilier la mise en valeur du cadre paysager et le tissu pavillonnaire
        existant est l’essence du projet de la ZAC des Coteaux d’Ormesson !{" "}
      </p>
    </div>
  );

  // Section 1
  const sectionOne = (
    <div className={classes.SectionLeft}>
      <div className={classes.TextArrowToRight}>
        <p>
          Dans ce cadre naturel exceptionnel, de <span>petites maisons</span> et
          de petits immeubles collectifs seront construits mettant en scène le
          caractère bucolique.
        </p>
        <div className={classes.ArrowToRight}>
          <img
            style={{ width: "100%" }}
            alt="Arrow"
            src={require(`../../../../assets/travaux/arrowRight.svg`)}
          />
        </div>
      </div>
      <div>
        <div className={classes.Illustration}>
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC1-1.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Section 2
  let sectionTwo = <div></div>;
  if (width < 768) {
    sectionTwo = (
      <div className={classes.SectionRight}>
        <div className={classes.TextArrowToLeft}>
          <p>
            Les espacements entre ces dernières forment des cadres pour{" "}
            <span>apprécier le grand paysage</span>, donner du rythme à la rue
            et assurer des respirations pour tous les riverains.
          </p>
          <div className={classes.ArrowToLeft}>
            <img
              style={{ width: "100%" }}
              alt="Arrow"
              src={require(`../../../../assets/travaux/arrowLeft.svg`)}
            />
          </div>
        </div>
        <div>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC1-2.svg`)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    sectionTwo = (
      <div className={classes.SectionRight}>
        <div>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%" }}
              alt="Illustration"
              src={require(`../../../../assets/travaux/NC1-2.svg`)}
            />
          </div>
        </div>
        <div className={classes.TextArrowToLeft}>
          <p>
            Les espacements entre ces dernières forment des cadres pour{" "}
            <span>apprécier le grand paysage</span>, donner du rythme à la rue
            et assurer des respirations pour tous les riverains.
          </p>
          <div className={classes.ArrowToLeft}>
            <img
              style={{ width: "100%" }}
              alt="Arrow"
              src={require(`../../../../assets/travaux/arrowLeft.svg`)}
            />
          </div>
        </div>
      </div>
    );
  }

  // Section 3
  const sectionThree = (
    <div className={classes.SectionLeft}>
      <div className={classes.TextArrowToRight}>
        <p>
          En contrebas, le long de la rue du pont de Chennevières un front bâti
          d’<span>immeubles plus hauts</span> sera constitué afin d’assumer une
          échelle plus urbaine.
        </p>
        <div className={classes.ArrowToRight}>
          <img
            style={{ width: "100%" }}
            alt="Arrow"
            src={require(`../../../../assets/travaux/arrowRight.svg`)}
          />
        </div>
      </div>
      <div>
        <div className={classes.Illustration}>
          <img
            style={{ width: "100%" }}
            alt="Illustration"
            src={require(`../../../../assets/travaux/NC1-3.svg`)}
          />
        </div>
      </div>
    </div>
  );

  // Button Valider
  // disabled={card.comment === "" && card.questions[0].value === ""}
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={comment === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Commentaire
  const commentaire = (
    <div className={classes.CommentSection}>
      <TextField
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        label="J'ai un commentaire !"
        style={{ width: "100%" }}
        color="secondary"
        size="small"
      />
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 768) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {title}
              {description}
              <div className={classes.Content}>
                {sectionOne} {sectionTwo} {sectionThree}
              </div>
              <div className={classes.Buttons}>
                {revenirButton}
                {validerButton}
              </div>
              {commentaire}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default NcOne;
